<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-typography-title>Tailored Delivery Solutions</a-typography-title>

      <div class="top-des">
        Welcome to Tailored Delivery Solutions
      </div>
      <div class="top-des">Delivering Excellence, Every Mile
      </div>
      <div class="top-des">
        At Tailored Delivery Solutions, ADS goes the extra mile to redefine logistics excellence. With our unrivaled
        expertise and cutting-edge technology, we provide customized solutions that meet your unique supply chain needs.
        Whether you're a small business or a large enterprise, we are committed to delivering seamless logistics services
        that drive your success.
      </div>
      <div class="top-des">
        To access the most recent content, kindly click on the icon below.
      </div>
      <a-row justify="space-between">
        <a-col :span="7">
          <div class="item" @click="$router.push('/resources/flexibleoptions')">
            <div class="pic">
              <img src="../../../assets/images/home/songhuo.svg" alt="">
            </div>
            Flexible Delivery Options
          </div>
        </a-col>
        <a-col :span="7">
          <div class="item" @click="$router.push('/about/warehousing')">
            <div class="pic">
              <img src="../../../assets/images/home/warehouse.svg" alt="">
            </div>
            Logistics Warehousing
          </div>
        </a-col>
        <a-col :span="7">
          <div class="item" @click="$router.push('/resources/deliverysolutions')">
            <div class="pic">
              <img src="../../../assets/images/home/jiejuefangan.svg" alt="">
            </div>
            Last-Mile Delivery Solutions
          </div>
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="7">
          <div class="item" @click="$router.push('/resources/applicationsolution')">
            <div class="pic">
              <img src="../../../assets/images/home/xingzhuang.svg" alt="">
            </div>
            Intelligent Driver Application Solution
          </div>
        </a-col>
        <a-col :span="7">
          <div class="item" @click="$router.push('/resources/orderassignment')">
            <div class="pic">
              <img src="../../../assets/images/home/shishi.svg" alt="">
            </div>
            Real-Time Order Assignment
          </div>
        </a-col>
        <a-col :span="7">
          <div class="item" @click="$router.push('/resources/sustainableoptions')">
            <div class="pic">
              <img src="../../../assets/images/home/huanbao.svg" alt="">
            </div>
            Sustainable Delivery Options
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku10.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top-des {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-2);
    margin-bottom: 15px;
  }

  // .quick {
  //   display: flex;
  //   justify-content: space-around;
  //   padding-top: 40px;
  //   padding-bottom: 20px;

  //   div {
  //     border-radius: 20px;
  //     cursor: pointer;
  //     background-color: #fff;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     color: var(--color-text-3);
  //     font-family: "Raleway";

  //     img {
  //       height: 64px;
  //       padding-bottom: 12px;
  //     }

  //     &:hover {
  //       color: rgb(var(--primary-6));
  //     }
  //   }
  // }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
    margin-bottom: 10px;
    padding-top: 40px;
  }

  .til1 {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-1);
  }

  .til2 {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-1);

    span {
      color: var(--color-text-2);
    }
  }

  .title1 {
    font-family: "Raleway";
    color: var(--color-text-1);
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
  }

  .des {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-2);
    padding-bottom: 10px;
  }
}

.arco-row {
  margin-top: 50px;

  .arco-col-7 {
    .item {
      border-radius: 20px;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--color-text-3);
      font-family: "Raleway";

      .pic {
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;

        img {
          height: 64px;
        }
      }

      &:hover {
        color: rgb(var(--primary-6));
        font-weight: 700;

        .pic {
          border-radius: 50%;
          background: #ECFFF1;
        }
      }
    }
  }
}
</style>
